<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ร้านค้า
        <v-icon>mdi-chevron-right</v-icon>
        จัดการร้านค้า
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
        <v-spacer />
        <v-btn :elevation="1" color="primary" class="pa-2" @click="openPageCreateShop()">
          <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
          สร้างร้านค้า
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2"> ประเภท : </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-radio-group row v-model="ShopType" dense hide-details>
                  <v-radio label="ทั้งหมด" value="0"></v-radio>
                  <v-radio label="ที่ใช้บริการอยู่" value="1"></v-radio>
                  <v-radio label="ที่ลบไปแล้ว" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2"> ร้านค้า : </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="name"
                  @change="(value) => (name = Trim_value(value))"
                  @keyup.enter="dataSearch()"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2"> Email : </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="email"
                  @change="(value) => (email = Trim_value(value))"
                  @keyup.enter="dataSearch()"
                  type="email"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2"> เบอร์โทร : </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="phone"
                  @change="(value) => (phone = Trim_value(value))"
                  @keyup.enter="dataSearch()"
                  @keypress="isNumberWNoDot($event)"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn
                  :elevation="1"
                  color="primary"
                  class="pa-2 mt-1"
                  dark
                  @click="dataSearch()"
                >
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2"> เงื่อนไข : </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-radio-group v-model="condition" dense hide-details>
                  <v-radio label="ทั้งหมด" :value="0"></v-radio>
                  <v-radio
                    label="ร้านที่ยอดคงเหลือน้อยกว่า Credit Limit"
                    :value="1"
                  ></v-radio>
                  <v-radio label="ร้านที่ยอดคงเหลือติดลบ" :value="2"></v-radio>
                  <v-radio label="ยอดคงเหลือมากกว่า 0" :value="3"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-if="branchId == '2'">
      <v-col cols="8"></v-col>
      <v-col cols="4" align="right">
        <vue-excel-xlsx
          id="GetExcel"
          v-show="false"
          :data="ShopList_excel"
          :columns="excel_header"
          :file-name="'รายชื่อร้านค้า'"
          :sheet-name="'รายชื่อร้านค้า'"
        >
        </vue-excel-xlsx>
        <v-btn
          :elevation="1"
          outlined
          color="success"
          class="pa-2"
          @click="ExportExcel()"
        >
          <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
          <span class="fn-12"> Export Excel </span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- ข้อมูลทั้งหมด -->
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0 pl-1">
        ทั้งหมด {{ formatMoney(ShopList.length) }} แถว
      </v-col>
    </v-row>

    <!-- PC hidden-xs-only -->
    <v-data-table
      :headers="headerTable"
      :items="ShopList"
      hide-default-footer
      :items-per-page="9999999999"
      class="packhai-border-table hidden-xs-only"
    >
      <template v-slot:item.No="{ item }">
        {{ formatMoney(ShopList.indexOf(item) + 1) }}
      </template>
      <template v-slot:item.name="{ item }">
        <div class="text-left">
          <span v-if="item.isActive == 1">{{ item.name }}</span>
          <span v-else>{{ item.name }} (รอเสนอราคา)</span>
        </div>
      </template>
      <template v-slot:item.email="{ item }">
        <div class="text-left">
          {{ item.email }}
        </div>
      </template>
      <template v-slot:item.balanceLimit="{ item }">
        {{
          item.balanceLimit != null
            ? formatMoney(parseFloat(item.balanceLimit).toFixed(2))
            : ""
        }}
      </template>
      <template v-slot:item.balance="{ item }">
        <a v-if="item.balance != null" @click="openFinanceShop(item.id)">
          <span v-if="item.balance < 0" class="color-red">{{
            formatMoney(parseFloat(item.balance).toFixed(2))
          }}</span>
          <span v-else-if="item.balance > 0" class="color-green">{{
            formatMoney(parseFloat(item.balance).toFixed(2))
          }}</span>
          <span v-else>{{ formatMoney(parseFloat(item.balance).toFixed(2)) }}</span>
        </a>
      </template>
      <template v-slot:item.createdDatetime="{ item }">
        {{ formatDatetime(item.createdDatetime) }}
      </template>
      <template v-slot:item.express="{ item }">
        <div class="text-left">
          {{ item.express }}
        </div>
      </template>

      <template v-slot:item.edit="{ item }">
        <a @click="EditShop(item.id)">
          <v-icon color="orange darken-3" size="18">fa-edit</v-icon>
        </a>
      </template>
      <template v-slot:item.delete="{ item }">
        <a v-if="!item.isDelete" @click="DeleteShop(item, ShopList.indexOf(item))">
          <v-icon color="red darken-3" size="18">fa-trash-alt</v-icon>
        </a>
      </template>
      <template v-slot:item.login="{ item }">
        <a v-if="item.isActive == 1" @click="login_shop(item.id)">
          <v-icon color="success">fa-sign-in-alt</v-icon>
        </a>
      </template>
      <template v-slot:item.omsStartDate="{ item }">
        <span>
          {{ formatDatetime(item.omsStartDate) }} - {{ formatDatetime(item.omsEndDate) }}
        </span>
      </template>
      <template v-slot:item.latestPaidDate="{ item }">
        <span>
          {{ formatDatetime(item.latestPaidDate) }}
        </span>
      </template>
      <template v-slot:item.lastOrderDate="{ item }">
        <span>
          {{ formatDatetime(item.lastOrderDate) }}
        </span>
      </template>
      <template v-slot:item.utm="{ item }">
        <span>
          {{ length60(item.utm) }}
        </span>
      </template>
    </v-data-table>

    <!-- Mobile hidden-sm-and-up -->
    <v-data-iterator
      :items="ShopList"
      :items-per-page="9999999999"
      hide-default-header
      hide-default-footer
      class="hidden-sm-and-up"
    >
      <template v-slot:default="props">
        <v-row class="ma-0">
          <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
            <v-card>
              <v-row class="packhai-showproduct-3 pa-2 py-3">
                <v-col cols="1" class="px-0">
                  {{ formatMoney(ShopList.indexOf(item) + 1) }}.
                </v-col>
                <v-col cols="8" class="px-0">
                  <span v-if="item.isActive == 1">{{ item.name }}</span>
                  <span v-else>{{ item.name }} (รอเสนอราคา)</span>
                </v-col>
                <v-col cols="3" class="text-right px-0">
                  <a v-if="item.balance != null" @click="openFinanceShop(item.id)">
                    <span v-if="item.balance < 0" class="color-red">{{
                      formatMoney(parseFloat(item.balance).toFixed(2))
                    }}</span>
                    <span v-else-if="item.balance > 0" class="color-grren">{{
                      formatMoney(parseFloat(item.balance).toFixed(2))
                    }}</span>
                    <span v-else>{{
                      formatMoney(parseFloat(item.balance).toFixed(2))
                    }}</span>
                  </a>
                </v-col>
              </v-row>
              <v-divider />
              <v-row class="packhai-showproduct-3 pa-2">
                <v-col cols="3" class="px-0"> Email : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ item.email }}
                </v-col>
              </v-row>
              <v-row class="packhai-showproduct-3 pa-2 py-2">
                <v-col cols="3" class="px-0"> Credit Limit : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{
                    item.balanceLimit != null
                      ? formatMoney(parseFloat(item.balanceLimit).toFixed(2))
                      : ""
                  }}
                </v-col>
              </v-row>
              <!-- <v-row class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0"> ขนส่ง : </v-col>
                <v-col cols="9" class="px-0">
                  <div>{{ item.express }}</div>
                </v-col>
              </v-row> -->
              <v-row class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0"> วันที่สร้าง : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ formatDatetime(item.createdDatetime) }}
                </v-col>
              </v-row>
              <v-row v-if="branchId == 2" class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0"> Utm : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ item.utm }}
                </v-col>
              </v-row>
              <v-row v-if="branchId == 2" class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0"> แพ็คเกจที่ซื้อ : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ item.currentPackage }}
                </v-col>
              </v-row>
              <v-row v-if="branchId == 2" class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0"> ระยะเวลาแพ็คเกจ : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ formatDatetime(item.omsStartDate) }} -
                  {{ formatDatetime(item.omsEndDate) }}
                </v-col>
              </v-row>
              <v-row v-if="branchId == 2" class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0">
                  วันที่ล่าสุดที่จ่ายเงินซื้อแพ็คเกจ :
                </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ formatDatetime(item.latestPaidDate) }}
                </v-col>
              </v-row>
              <v-row v-if="branchId == 2" class="packhai-showproduct-3 pa-2 pb-1">
                <v-col cols="3" class="px-0"> วันที่ล่าสุดที่เปิดออเดอร์ : </v-col>
                <v-col cols="9" class="px-0 text-truncate">
                  {{ formatDatetime(item.lastOrderDate) }}
                </v-col>
              </v-row>
              <v-row class="packhai-showproduct-3 pa-2">
                <v-col cols="12" class="px-0 text-right">
                  <a @click="EditShop(item.id)" class="pa-2">
                    <v-icon color="orange darken-3" size="16">fa-edit</v-icon>
                  </a>
                  <a
                    v-if="!item.isDelete"
                    @click="DeleteShop(item, ShopList.indexOf(item))"
                    class="pa-2"
                  >
                    <v-icon color="red darken-3" size="16">fa-trash-alt</v-icon>
                  </a>
                  <a v-if="item.isActive == 1" @click="login_shop(item.id)" class="pa-2">
                    <v-icon color="success" size="16">fa-sign-in-alt</v-icon>
                  </a>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import { shopService_dotnet, branchService_dotnet } from "@/website/global";
import { AlertSuccess, AlertError } from "@/website/global_alert";
import {
  formatMoney,
  formatDatetime,
  isNumberWNoDot,
  Trim_value,
  Trim_rules,
  length60,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    page_loading: true,
    loading: false,
    ShopType: "1",
    headerTable: null,
    headerShop: [
      { align: "center", text: "No", value: "No", sortable: false },
      { align: "center", text: "ร้านค้า", value: "name", sortable: false },
      { align: "center", text: "Email", value: "email", sortable: false },
      { align: "right", text: "Credit limit", value: "balanceLimit", sortable: false },
      { align: "right", text: "ยอดเงิน", value: "balance", sortable: false },
      {
        align: "center",
        text: "วันที่สร้างร้านค้า",
        value: "createdDatetime",
        sortable: false,
      },
      //{ align: "center", text: "PDF", value: "print", sortable: false },
      { align: "center", text: "แก้ไข", value: "edit", sortable: false },
      { align: "center", text: "ลบ", value: "delete", sortable: false },
      { align: "center", text: "", value: "login", sortable: false },
    ],
    headerOasys: [
      { align: "center", text: "No", value: "No", width: "50px" },
      { align: "center", text: "ร้านค้า", value: "name", width: "50px" },
      { align: "center", text: "Email", value: "email", width: "50px" },
      { align: "right", text: "Credit limit", value: "balanceLimit", width: "50px" },
      { align: "right", text: "ยอดเงิน", value: "balance", width: "50px" },
      {
        align: "center",
        text: "วันที่สร้างร้านค้า",
        value: "createdDatetime",
        width: "50px",
      },
      { align: "center", text: "utm", value: "utm", width: "50px" },
      { align: "center", text: "แพ็คเกจที่ซื้อ", value: "currentPackage", width: "50px" },
      { align: "center", text: "ระยะเวลาแพ็คเกจ", value: "omsStartDate", width: "50px" },
      {
        align: "center",
        text: "วันที่ล่าสุดที่จ่ายเงินซื้อแพ็คเกจ",
        value: "latestPaidDate",
        width: "50px",
      },
      {
        align: "center",
        text: "วันที่ล่าสุดที่เปิดออเดอร์",
        value: "lastOrderDate",
        width: "50px",
      },

      { align: "center", text: "แก้ไข", value: "edit", width: "50px" },
      { align: "center", text: "ลบ", value: "delete", width: "50px" },
      // { align: 'center', text: 'ลบ', value: 'delete',   width: "50px" },
      { align: "center", text: "", value: "login", width: "50px" },
    ],
    //excel
    excel_header: [
      { label: "No", field: "No", width: 50 },
      { label: "ร้านค้า", field: "name", width: 50 },
      { label: "Email", field: "email", width: 50 },
      { label: "Credit limit", field: "balanceLimit", width: 50 },
      { label: "ยอดเงิน", field: "balance", width: 50 },
      { label: "ขนส่ง", field: "express", width: 50 },
      { label: "วันที่สร้างร้านค้า", field: "createdDatetime", width: 50 },
      { label: "utm", field: "utm", width: 50 },
      { label: "แพ็คเกจที่ซื้อ", field: "currentPackage", width: 50 },
      { label: "ระยะเวลาแพ็คเกจ", field: "omsStartDate", width: 50 },
      { label: "วันที่ล่าสุดที่จ่ายเงินซื้อแพ็คเกจ", field: "latestPaidDate", width: 50 },
      { label: "วันที่ล่าสุดที่เปิดออเดอร์", field: "lastOrderDate", width: 50 },
      { label: "PDF", field: "print", width: 50 },
    ],
    ShopList: [],
    ShopList_excel: [],

    name: null,
    email: null,
    phone: null,
    condition: 0,
    branchId: parseInt(localStorage.getItem("Branch_BranchID")),
  }),
  watch: {},
  async created() {
    document.title = this.$router.history.current.meta.title;

    var dataSearch = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      condition: this.condition,
    };
    await this.GetShopList(dataSearch);
    this.page_loading = false;
  },
  methods: {
    ResetHeader() {
      if (this.branchId != 2) {
        if (this.ShopType == 2) {
          this.headerTable = this.headerShop.filter((x) => x.value != "delete");
        } else {
          this.headerTable = this.headerShop;
        }
      } else {
        this.headerTable = this.headerOasys;
      }
    },
    async GetShopList(dataSearch) {
      this.ResetHeader();
      if (this.branchId == 2) {
        this.loading = true;
        let response = await axios.post(
          shopService_dotnet + "Shop/get-shop-list-oasys",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
            shopType: parseInt(this.ShopType),
            dataSearch: dataSearch,
          },
          { headers: header_token }
        );
        this.loading = false;
        if (response.status == 200) {
          this.ShopList = this.GenDataShop(response.data.result);
        }
      } else {
        this.loading = true;
        let response = await axios.post(
          shopService_dotnet + "Shop/get-shop-list",
          {
            branchId: localStorage.getItem("Branch_BranchID"),
            shopType: parseInt(this.ShopType),
            dataSearch: dataSearch,
          },
          { headers: header_token }
        );
        this.loading = false;
        if (response.status == 200) {
          this.ShopList = this.GenDataShop(response.data.result);
        }
      }
    },

    GenDataShop(ShopList) {
      var data = [];
      for (var i in ShopList) {
        data.push(ShopList[i]["shop"]);
        data[i]["express"] = ShopList[i]["express"];
        // if (data[i]["balanceLimit"]==0 || data[i]["balanceLimit"]==null || data[i]["balanceLimit"]==""){
        //   data[i]["balanceLimit"] = 0
        // }
        // if (data[i]["balance"]==0 || data[i]["balance"]==null || data[i]["balance"]==""){
        //   data[i]["balance"] = 0
        // }
      }
      return data;
    },

    async login_shop(shopId) {
      this.loading = true;
      let response = await axios.post(
        shopService_dotnet + "Shop/get-master-user",
        { shopId: shopId },
        { headers: header_token }
      );
      this.loading = false;
      if (response.status == 200) {
        // window.open("login?token="+response.data);
        var branchid = parseInt(localStorage.getItem("Branch_BranchID"));
        let branch_response = await axios.post(
          branchService_dotnet + "Branch/get-branch-info?BranchID=" + branchid,
          {},
          { headers: header_token }
        );

        if (branch_response.data.domain != null) {
          window.open(
            "https://shop." +
              branch_response.data.domain +
              "/login?token=" +
              response.data.token
          );
        } else if (branch_response.data.branchType == 2) {
          window.open("https://oms.oasys.co.th/login?token=" + response.data.token);
        } else {
          window.open("https://shop.packhai.com/login?token=" + response.data.token);
        }
      }
    },

    EditShop(ShopID) {
      localStorage.setItem("CreateShop_ShopID", ShopID);
      this.$router.push("/manageshop/create");
    },

    DeleteShop(item, index) {
      Swal.fire({
        position: "top",
        icon: "warning",
        text: "ร้านค้า: " + item.name + " ต้องการลบใช่หรือไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          let response = await axios.post(
            shopService_dotnet + "Shop/delete-shop",
            {
              shopId: item.id,
            },
            { headers: header_token }
          );
          this.loading = false;
          if (response.status == 200) {
            this.AlertSuccess();
            this.ShopList.splice(index, 1);
          } else {
            this.AlertError();
          }
        }
      });
    },

    async openPageCreateShop() {
      localStorage.setItem("CreateShop_ShopID", 0);
      this.$router.push("/manageshop/create");
    },

    async openFinanceShop(ShopID) {
      var path = "/shop-balance?sid=" + ShopID;
      window.open(path);
    },

    dataSearch() {
      if (this.name == "") {
        this.name = null;
      }
      if (this.email == "") {
        this.email = null;
      }
      if (this.phone == "") {
        this.phone = null;
      }

      var dataSearch = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        condition: this.condition,
      };
      this.GetShopList(dataSearch);
    },

    async PrintPreview(ShopID) {
      localStorage.setItem("CreateShop_ShopID", ShopID);
      window.open("/print-pricequotation");
    },
    async ExportExcel() {
      this.ShopList_excel = [];

      var No = 0;
      var resultData = this.ShopList;
      for (var item of resultData) {
        var obj = [];
        obj.No = No++;
        obj.name = item.name;
        obj.email = item.email;
        obj.balanceLimit = item.balanceLimit;
        obj.balance = item.balance;
        obj.express = item.express;
        obj.createdDatetime = item.createdDatetime;
        obj.utm = item.utm;
        obj.currentPackage = item.currentPackage;
        obj.omsStartDate =
          item.omsStartDate != null
            ? item.omsStartDate
            : "" + "-" + item.omsEndDate != null
            ? item.omsEndDate
            : "";
        obj.latestPaidDate = item.latestPaidDate;
        obj.lastOrderDate = item.lastOrderDate;
        this.ShopList_excel.push(obj);
      }
      await document.getElementById("GetExcel").click();
    },
    formatMoney,
    formatDatetime,
    isNumberWNoDot,
    Trim_value,
    Trim_rules,
    AlertSuccess,
    AlertError,
    length60,
  },
};
</script>
